<!--**
 * This component displays the currency upto two decimal points.
 * And different currency format in En and Fr
 */-->
<template>
	<span>{{ getCurrency(currency) }}</span>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
	name: 'DisplayCurrency',
	props: {
		currency: {
			type: Number,
			default: null
		}
	}
})
export default class DisplayCurrency extends Vue {
	getCurrency(number) {
		return this.$store.state.i18n.locale === 'en'
			? `${number.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' })}`
			: `${number.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })}`;
	}
}
</script>
